import React from "react";
import "./footer.css"

const Footer=()=>{
    return(
        <>
        <footer id="footer">
        <p className="footer_para"> 2022 &#169; Laundry</p>
        </footer>
        </>
    )
}

export default Footer;
